@import "./assets/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .top-shadow {
        text-shadow: 0 0 5px #000;
    }

    #imprint h1, #imprint h2, #imprint h3, #imprint h4, #imprint h5 {
        @apply antialiased;
        @apply font-thin;
        @apply break-words;
        font-family: sans-serif;
        color: white;
    }

    #imprint p {
        @apply break-words;
        @apply pb-4;
        @apply text-gray-500;
        font-size: 1rem;
        font-family: serif;
    }

    #imprint span, #imprint a {
        @apply text-white;
    }

    #imprint h1 {
        @apply sm:text-9xl;
        @apply text-5xl;
        @apply pt-8;
        @apply pb-8;
    }

    #imprint h2 {
        @apply sm:text-8xl;
        @apply text-5xl;
        @apply text-accent;
        @apply pt-4;
        @apply pb-4;
    }

    #imprint h4 {
        @apply text-xl;
        @apply pt-4;
        @apply pb-4;
    }

    #imprint h5 {
        @apply text-xl;
        @apply pt-2;
        @apply pb-2;
    }

}