/* lora-regular - latin */
@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    src: url('./lora-v26-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./lora-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./lora-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./lora-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./lora-v26-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./lora-v26-latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lora-500 - latin */
@font-face {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    src: url('./lora-v26-latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./lora-v26-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./lora-v26-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./lora-v26-latin-500.woff') format('woff'), /* Modern Browsers */
    url('./lora-v26-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./lora-v26-latin-500.svg#Lora') format('svg'); /* Legacy iOS */
}

/* lato-300 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 300;
    src: url('./lato-v23-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./lato-v23-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./lato-v23-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./lato-v23-latin-300.woff') format('woff'), /* Modern Browsers */
    url('./lato-v23-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./lato-v23-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./lato-v23-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('./lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./lato-v23-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('./lato-v23-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('./lato-v23-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./lato-v23-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
